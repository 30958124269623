.module-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
}

.module-wrapper :global .accordion-item {
    width: 100%;
    border: none;
}

.module-wrapper :global .accordion-header {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.module-wrapper :global button.accordion-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: #121212;
}

.module-wrapper :global button.accordion-button img {
    width: 40%;
    margin-top: 20px;
    margin-bottom: 80px;
}

.module-wrapper :global button.accordion-button::after {
    display: none;
}

.module-wrapper :global .accordion-item:nth-child(2) :local .module-name {
    background-color: #0372F4;
}

.module-wrapper :global .accordion-item:nth-child(3) :local .module-name {
    background-color: #E30377;
}

.module-name {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: LeagueSpartan;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    background-color: #0EBFBE;
    padding: 16px 10px;
}

.journey-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 1px solid #EDEDED;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
}

.journey-list-item, 
.journey-list-item-disabled {
    background-color: #FFFFFF;
    padding: 12px 24px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    color: #121212;
}

.journey-list-item:hover {
    background-color: #eeeeee;
    color: #121212;
    transition: 0.3s;
}

.journey-list-item-disabled {
    pointer-events: none;
    color: #b5b5b5;
}

.saved-videos {
    position: relative;
}

.saved-videos::after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color:#9D9D9D;
    margin-top: 31px;
    margin-bottom: 37px;
}

@media screen and (max-width: 600px) {
    .module-wrapper {
        display: flex;
        flex-direction: column;
    }

    .module-wrapper :global .accordion-item {
        width: 100%;
        margin-bottom: 24px;
    }
}