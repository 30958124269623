.journey-custom-navbar {
    transition: 0.3s;
    background: #FFF;

    .navbar-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        
        .space-between-menu {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .profile-dropdown .dropdown-toggle::after {
            display: none;
        }

        .dropdown-menu {
            margin-left: -100px;
        }
    }

    .container-fluid {
        padding: 0 70px;
    }

    .nav-link {
        margin: 0 67px;
    }

    .settings {
        margin: 0 15px;
    }

    &__searchbox {
        .form-control {
            background-color: #C4C4C4;
            border-radius: 0;
            color: #12121299;
        }
    }

    .user-img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        align-self: center;
        border-radius: 50%;
    }

    .language-picker { 
        img {
            height: 24px;
        }

        .btn-primary {
            position: relative;
            background-color: #FFF;
            box-shadow: none;
            border-color: #FFF;
            border-radius: 0;
        }
        .dropdown-menu {
            border-radius: 0;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }
}

.journey-custom-navbar-onscroll {
    transition: 0.3s;
    box-shadow: 0px 5px 15px rgba(177, 165, 165, 0.28);
}

@media only screen and (max-width: 600px) {
    .journey-custom-navbar {

        .container-fluid {
            padding: 0 20px;
        }

        .navbar-nav {
            flex-direction: column;
        }
    
        .space-between-menu {
            flex-direction: column;
        }

        .user-profile {
            display: flex;
            justify-content: center;
        }
        
        .tetris-decoration {
            display: none;
        }
    }
}