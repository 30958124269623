@font-face {
    font-family: Aileron;
    src: url(assets/fonts/Aileron-Regular.woff2);
    font-weight: 400;
 }
 
 @font-face {
    font-family: Aileron;
    src: url(assets/fonts/Aileron-Bold.woff2);
    font-weight: 600;
 }
 
 @font-face {
    font-family: LeagueSpartan;
    src: url(assets/fonts/LeagueSpartan-Bold.woff2);
    font-weight: bold;
 }

 h1, h2, h3, h4, h5 {
     font-family: LeagueSpartan;
 }

 .container {
    width: 100%;
 }