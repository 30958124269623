.video-detail-menu {
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.video-detail-submenu {
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    margin-top: 8px;
}

.video-details-nav {
    display: flex;
    flex-direction: column;
}

.video-detail-menu:global.nav-link {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

div.video-details-nav :global .nav-link {
    color: #000000;
}

div.video-details-nav :global .nav-link.active {
    background-color: transparent;
    color: #0372F4;
}

.video-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.saved-video-wrapper {
    display: none;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 12px 16px;
    border: 1px solid #EDEDED;
    background-color: #FFFFFF;
    margin-top: 100px;
}

.saved-video-desktop {
    display: block;
    cursor: pointer;
}

.video-iframe {
    width: 100%;
    height: 100%;
    position: absolute;
}

.iframe-wrapper {
    position: relative;
    padding-top: 25px;
    padding-bottom: 56.25%;
    height: 0px;
    margin-bottom: 24px;
}

.iframe-wrapper>div {
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .video-title-wrapper {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .video-title-wrapper>div {
        align-self: flex-end;
    }
}